import APP_CONST from '@/constants/AppConst';
import { Component, Watch, Mixins, Inject } from 'vue-property-decorator';
import analyticsStore from '@/store/modules/analyticsStore';
import AnalyticsProgramDropdown from '../../commonComponents/analyticsProgramDropdown/AnalyticsProgramDropdown.vue';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BarChart from '@/chartcomponents/barcomponent/BarComponent.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import ExportDropdown from '@/commoncomponents/exportdropdown/exportDropdown.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  components: {
    'analytics-program-dropdown': AnalyticsProgramDropdown,
    'BarChart': BarChart,
    'multi-select-dropdown': MultiSelectDropdown,
    'export-to-csv': ExportDropdown,
    'bouncing-preloader': BouncingPreloaderComponent,
  }
})


export default class ScholarEnrollmentAnalyticsComponent extends Mixins(DeviceWidthCheckerMixin) {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  private objScreenText: ScreenText = new ScreenText();
  localAppConstant = APP_CONST;
  public isMobileView: boolean = false;
  public enrollmentCSVlistOptions: any = APP_CONST.SCHOLARS_CSV_ENROLLMENT_LIST;
  public disableDownloadButton: boolean = false;
  public multiSiteDropdown = APP_CONST.ENROLLMENT_SESSIONS_CONFIG;
  public responseReceived: boolean = false;
  public yAxisLabelString: string = 'Enrolled';
  public xAxisLabelString: string = '';
  public isDisplayXLabel: boolean = false;
  public barChartBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public barChartHoverBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public isTabletView: boolean = false;
  public isLineChartLoaderVisible: boolean = false;
  public oldSelectedSiteData: number[] = [];

  @Watch('sitesAnalyticsDetails', { deep: true })
  updateSitesUpdatedData(val: any) {
    if (val && val.length) {
      this.oldSelectedSiteData = [];
      val.forEach((site: any) => {
        this.oldSelectedSiteData.push(site.siteId);
      });
    }
  }

  get barChartData() {
    return analyticsStore.barChartAnalyticsData;
  }

  get selectedProgramData() {
    return analyticsStore.slectedProgram;
  }

  get sitesDropdown() {
    return analyticsStore.selectedprogramSites;
  }

  get dropdownResponseStatus() {
    return analyticsStore.programDropdownStatus;
  }

  get selectedSiteIds() {
    return analyticsStore.selectedSites;
  }

  get isDisabledDropdown() {
    return this.responseReceived && this.sitesDropdown && ((this.sitesDropdown.length == APP_CONST.ZERO) || (this.sitesDropdown.length == APP_CONST.ONE));
  }

  get sitesAnalyticsDetails() {
    return analyticsStore.sitesAnalyticsDetails;
  }

  get barChartStyle() {
    let allApplicationLength: number = 0;
    if (this.isMobile) {
      allApplicationLength = (this.barChartData && this.barChartData.length)
        ? this.barChartData.length * (this.barChartData.length * 50 > 110
          ? 110
          : this.barChartData.length * 50)
        : 0;
    }
    else {
      allApplicationLength = (this.barChartData && this.barChartData.length)
        ? this.barChartData.length * (this.barChartData.length * 10 > 110
          ? 110
          : this.barChartData.length * 10)
        : 0;
    }
    const styles: any = {
      width: allApplicationLength > document.documentElement.clientWidth
        ? `${allApplicationLength}px`
        : '100%',
      height: '100%',
      position: 'relative'
    };
    return styles;
  }

  get maxYAxisData() {
    const data: any = {};
    if (this.barChartData && this.barChartData.length) {
      const maxYY = this.barChartData.map((element: any) => {
        return element.enrolledScholars;
      });
      const maxYValue = Math.max(...maxYY);
      let finalYValue: number = maxYValue;
      if (maxYValue < 100) {
        finalYValue = Math.round(maxYValue / 10) * 10;
        return finalYValue > maxYValue
          ? finalYValue
          : finalYValue + 10;
      }
      else if (maxYValue < 1000) {
        finalYValue = Math.round(maxYValue / 50) * 50;
        return finalYValue > maxYValue
          ? finalYValue
          : finalYValue + 50;
      }
      else {
        finalYValue = Math.round(maxYValue / 500) * 500;
        return finalYValue > maxYValue
          ? finalYValue
          : finalYValue + 500;
      }

    }
    else {
      return APP_CONST.HUNDRED;
    }
  }

  get isAnalyticsDataReceived() {
    return analyticsStore.isAnalyticsDataReceived;
  }

  get isSubmissionEmptyState() {
    let sum: number = 0;
    if (this.barChartData.length) {
      this.barChartData.forEach((site: any) => sum += site.enrolledScholars);
    }
    return (sum == APP_CONST.ZERO);
  }

  get enrollmentCsvDropdown() {
    return analyticsStore.enrollmentCsvDropdown;
  }

  get disableCsvButtonEnrollment() {
    return analyticsStore.disableDownloadCsvButtonEnrollment;
  }

  get isEnrollmentTabLoaderVisible() {
    return analyticsStore.isEnrollmentLoader;
  }

  isTablet() {
    this.isTabletView = APP_UTILITIES.tabletCheck();
  }

  beforeMount() {
    this.isLineChartLoaderVisible = true;
    this.isMobileView = APP_UTILITIES.isMobile();
    this.isTabletView = APP_UTILITIES.tabletCheck();
    window.addEventListener('resize', APP_UTILITIES.debounce(this.isTablet));
    analyticsStore.mutateScholarNavigationSelection('enrollment');
    if (APP_UTILITIES.getFilteredColumns('enrollmentselectedprogram')) {
      const program: any = APP_UTILITIES.getFilteredColumns('enrollmentselectedprogram');
      const selectedProgram = JSON.parse(program);
      analyticsStore.mutateSelectedProgramName(selectedProgram);
    }
    else {
      analyticsStore.programNameSelection();
    }
  }

  mounted() {
    setTimeout(() => {
      this.getProgramSites();
    }, 500);
  }
  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  /**
   * Api call for selected program sites
   *
   */
  getProgramSites() {
    if (this.selectedProgramData && this.selectedProgramData.programId) {
      analyticsStore.getProgramSites(this.selectedProgramData.programId).then(() => {
        this.responseReceived = true;
        this.isLineChartLoaderVisible = false;
      });
    }
  }

  selectProgramData(selectedProgram: any) {
    this.isLineChartLoaderVisible = true;
    APP_UTILITIES.setFilteredColumns('enrollmentselectedprogram', selectedProgram);
    analyticsStore.getProgramSites(selectedProgram.programId).then(() => {
      this.responseReceived = true;
      this.isLineChartLoaderVisible = false;
    });
  }

  get barEmtyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    if (this.isSubmissionEmptyState && this.isAnalyticsDataReceived && this.sitesDropdown && this.sitesDropdown.length) {
      emptyStateMessage = APP_CONST.NO_ENROLLMENT_EMPTY_STATE;
    }
    else if (this.responseReceived && !this.sitesDropdown.length) {
      emptyStateMessage = APP_CONST.NO_SITE_EMPTY_STATE;
    }
    return emptyStateMessage;
  }

  fetchSitesAnalyticsData() {
    if (JSON.stringify(this.oldSelectedSiteData) != JSON.stringify(this.selectedSiteIds)) {
      this.isLineChartLoaderVisible = true;
      analyticsStore.fetchSitesAnalyticsData(this.selectedProgramData.programId).then((response) => {
        this.isLineChartLoaderVisible = false;
        this.oldSelectedSiteData = this.selectedSiteIds;
      });
    }
  }

  /**
   * Fetch api data on the basis of selected sites
   *
   * @param eventData
   */
  selectSite(eventData: any) {
    if (eventData.selectedValue != undefined) {
      analyticsStore.mutateEnrollmentSiteSession({ programId: this.selectedProgramData.programId, site: eventData });
    }
  }

  enrollmentCsvDownload(arg: any) {
    analyticsStore.downloadCsvEnrollment(arg);

    if (arg.key === APP_CONST.DOWNLOAD_CONSOLIDATED_ROSTER_CSV_KEY) {
      this.analyticsService.track(analyticsEventNames.CONSOLIDATED_ROSTER_DOWNLOADED);
    }
  }
}

